export default {
    zh:{
        xyzJd:{
            queryParam:{
                mainId:'信用证号',
                mainId_placeholder:'请输入信用证号',
                date:'交单日期',
                date_placeholder:'请选择交单日期',
            },
            columns:{
                F_MAIN_ID:'信用证号',
                F_DATE:'交单日期',
                F_AMOUNT:'交单金额',
                SYS_ADD_USER:'创建人',
            },
            form:{
                mainId:'信用证号',
                mainId_placeholder:'请选择信用证号',
                mainId_rule: '请选择信用证号',
                orderId:'订单',
                orderId_placeholder:'请选择订单',
                orderId_rule: '请选择订单',
                date:'交单日期',
                date_placeholder:'请选择交单日期',
                date_rule: '请选择交单日期',
                amount:'交单金额',
                amount_placeholder:'请输入交单金额',
                amount_rule: '请输入交单金额',
            },
        }
    },
    en:{
        xyzJd:{
            queryParam:{
                mainId:'信用证号',
                mainId_placeholder:'请输入信用证号',
                date:'交单日期',
                date_placeholder:'请选择交单日期',
            },
            columns:{
                F_MAIN_ID:'信用证号',
                F_DATE:'交单日期',
                F_AMOUNT:'交单金额',
                SYS_ADD_USER:'创建人',
            },
            form:{
                mainId:'信用证号',
                mainId_placeholder:'请选择信用证号',
                mainId_rule: '请选择信用证号',
                orderId:'订单',
                orderId_placeholder:'请选择订单',
                orderId_rule: '请选择订单',
                date:'交单日期',
                date_placeholder:'请选择交单日期',
                date_rule: '请选择交单日期',
                amount:'交单金额',
                amount_placeholder:'请输入交单金额',
                amount_rule: '请输入交单金额',
            },
        }
    }
}